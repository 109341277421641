import Link from "next/link";
import styles from './index.module.scss';
import Image from "/components/CustomImage";

const CategoryIcon = ({ iconArr = "[]", page, isMobileType }) => {
    const itemArr = JSON.parse(iconArr) || [];

    if (itemArr?.length == 0) {
        return null
    }

    if (page == 'plp' && isMobileType) {
        return (
            <div className={styles.categoryIconParentPLP}>
                {itemArr?.map((item, index) => (
                    <Link href={item.url} key={index} className={styles.categoryIconLink}>
                        <p className={styles.categoryIconText}>{item?.text}</p>
                        <Image loading='lazy' src={item.image} alt={item?.alt}
                            width={600}
                            height={600}
                            placeholder='blur' blurDataURL='/images/kama-placeholder.jpg' />
                    </Link>
                ))}
            </div>
        )
    }

    return (
        <div className={styles.categoryIconParent}>
            {itemArr?.map((item, index) => (
                <Link href={item.url} key={index} className={`${styles.categoryIconLink} ${page == 'plp' ? styles.plpImage : ''}`}>
                    <p className={styles.categoryIconText}>{item?.text}</p>
                    <Image loading='lazy' src={item.image} alt={item?.alt}
                        width={600}
                        height={600}
                        placeholder='blur' blurDataURL='/images/kama-placeholder.jpg' />
                </Link>
            ))}
        </div>
    )

}

export default CategoryIcon;